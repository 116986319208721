const en = {
  termipass: "TermiPass",
  download: 'Download',
  title: {
    content_1: 'Your secure bridge to Terminus',
    content_2: 'Access everything, anywhere, on'
  },
  dsc_1:
    "If you haven't got a Terminus Name yet, register one on TermPass mobile before anything else.",
  dsc_2:
    "Terminus Name creation and Terminus activation are only available on TermiPass mobile.",
  dsc_3:
    "Available on macOS, Windows, iOS, iPadOS, Android and as a Chrome browser extension.",
  icon_name_1: "macOS",
  icon_name_2: "Windows",
  icon_name_3: "Google Play",
  icon_name_4: "iOS & iPadOS",
  icon_name_5: "Android",
  icon_name_6: "Chrome",
  title_2: "Features Across Clients",
  category: "Category",
  features: "Features",
  mobile: "Mobile",
  desktop: "Desktop",
  browser: 'Browser',
  device: 'device',
  chrome_extension: "Chrome Extension",
  account_management: "Account management",
  account_features_1: "Create Terminus Name",
  account_features_2: "Import Terminus Name",
  account_features_3: "Multi-account management ",
  account_features_4: "SSO login ",
  device_network_management: "Device & network management",
  device_features_1: "Activate Terminus",
  device_features_2: "View resource consumption",
  device_features_3: "Remote device control",
  device_features_4: "Manage VPN Connections",
  knowledge_file_management: "Knowledge & File Management",
  knowledge_features_1: "Sync files across devices and SaaS accounts",
  knowledge_features_2: "Manage files on Terminus",
  knowledge_features_3: "Collect Webpage/PDF/Video",
  knowledge_features_4: "Backup your photos and files on phone",
  secret_management: "Secret management",
  secret_features_1:
    "Generate, share, and autofill strong passwords and passkeys",
  secret_features_2: "One-time authentication management",
  secret_features_3: "Cookies Sync",
  secret_features_4: "3rd-party SaaS account integration",
  secret_features_5: "Verifiable Credential (VC) card management",
  productivity: "Productivity*",
  productivity_features_1: "Online meeting",
  productivity_features_2: "Calendar",
  productivity_features_3: "Instant messaging",
  productivity_features_4: "Group messaging",
  ai: "AI*",
  ai_features_1: "Chat with local AI agents on Terminus",
  ai_features_2: "Immersive translation",
  ai_features_3: "Summarize webpages and documents",
  ai_features_4: "AI assisted writing",
  ai_features_5: "AI assisted search",
  wallet: "Wallet*",
  wallet_features_1:
    "Transactions in 10,000+ currencies across 150+ blockchains",
  wallet_features_2: "Cross-chain bridges and swaps based on Otmoic protocol",
  features_dsc: '*Productivity, AI, and Wallet related features are coming soon. For more details, visit the <span class="features-dsc-link">TermiPass documentation.</span>'
};

export default en;
