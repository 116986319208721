<template>
  <div class="bottom-content">
    <div>
      <div class="logo-container">
        <img class="log-img" src="../../assets/v2/logo.svg" alt="" />
        <span class="logo-name">Terminus</span>
      </div>
      <div class="opacity-text left-text">
        {{ $t("footer_dsc") }}
      </div>
      <div class="link-container">
        <a
          :href="link.footer_github"
          target="_blank"
          rel="noopener noreferrer"
          @mouseenter="mouseenterHandler(0)"
          @mouseleave="mouseleaveHandler"
        >
          <img :src="active === 0 ? icon1Active : icon1" alt="" />
        </a>
        <span
          class="wechat-popup"
          @mouseenter="mouseenterHandler(5)"
          @mouseleave="mouseleaveHandler"
          v-if="lang_zh"
        >
          <img :src="active === 5 ? icon5Active : icon5" alt="" />
          <div class="qr-container">
            <div class="qr-wrapper">
              <img class="qr-img" src="../../assets/v2/wechatQR.jpg" alt="" />
            </div>
          </div>
        </span>
        <a
          :href="link.footer_discord"
          target="_blank"
          rel="noopener noreferrer"
          @mouseenter="mouseenterHandler(1)"
          @mouseleave="mouseleaveHandler"
          v-if="!lang_zh"
        >
          <img
            class="link-2"
            :src="active === 1 ? icon2Active : icon2"
            alt=""
          />
        </a>
        <a
          :href="link.footer_twitter"
          target="_blank"
          rel="noopener noreferrer"
          @mouseenter="mouseenterHandler(2)"
          @mouseleave="mouseleaveHandler"
          v-if="!lang_zh"
        >
          <img
            class="link-2"
            :src="active === 2 ? icon3Active : icon3"
            alt=""
          />
        </a>
        <a
          :href="link.footer_medium"
          target="_blank"
          rel="noopener noreferrer"
          @mouseenter="mouseenterHandler(3)"
          @mouseleave="mouseleaveHandler"
        >
          <img
            class="link-2"
            :src="active === 3 ? icon4Active : icon4"
            alt=""
          />
        </a>
      </div>
      <div class="opacity-text info" v-if="!lang_zh">@2024 BEC LAB</div>
    </div>
    <div class="footer-item-container">
      <div class="footer-item-layout">
        <div class="footer-item-title">{{ $t("product") }}</div>
        <a
          class="opacity-text footer-item-text link-flash2"
          target="_blank"
          :href="link.footer_terminus_os"
          >Terminus</a
        >
        <a
          class="opacity-text footer-item-text link-flash2"
          target="_blank"
          :href="link.footer_terminus_space"
          >Terminus Space</a
        >
        <a
          class="opacity-text footer-item-text link-flash2"
          target="_blank"
          :href="link.footer_termi_pass"
          >TermiPass</a
        >
      </div>
      <div class="footer-item-layout" v-if="!lang_zh">
        <div class="footer-item-title">{{ $t("protocol") }}</div>
        <a
          class="opacity-text footer-item-text link-flash2"
          :href="link.footer_snowinning"
          target="_blank"
          >Snowinning</a
        >
        <a
          class="opacity-text footer-item-text link-flash2"
          :href="link.footer_otmoic"
          target="_blank"
          >Otmoic</a
        >
        <a
          class="opacity-text footer-item-text link-flash2"
          :href="link.footer_market"
          target="_blank"
          >{{ $t("market") }}</a
        >
        <a
          class="opacity-text footer-item-text link-flash2"
          :href="link.footer_recomend"
          target="_blank"
          >{{ $t("recommend") }}</a
        >
      </div>
      <div class="footer-item-layout">
        <div class="footer-item-title">{{ $t("resource") }}</div>
        <a
          class="opacity-text footer-item-text link-flash2"
          :href="link.footer_docs"
          target="_blank"
          >{{ $t("docs") }}</a
        >
      </div>
      <!-- <div class="footer-item-layout footer-item-layout2">
            <div class="footer-item-title">Company</div>
            <div class="opacity-text footer-item-text2">
              <div class="item-child">
                <img src="@/assets/v2/local.svg" alt="" />
                <div class="item-text">
                  8502 Preston Rd. Inglewood, Maine 98380, USA
                </div>
              </div>
            </div>
          </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

import icon1Active from "../../assets/v2/github-circle2.svg";
import icon2Active from "../../assets/v2/temp-circle2.svg";
import icon3Active from "../../assets/v2/twiter-circle2.svg";
import icon4Active from "../../assets/v2/bg-circle2.svg";
import icon5Active from "../../assets/v2/wechat-circle2.svg";

import icon1 from "../../assets/v2/github-circle.svg";
import icon2 from "../../assets/v2/temp-circle.svg";
import icon3 from "../../assets/v2/twiter-circle.svg";
import icon4 from "../../assets/v2/bg-circle.svg";
import icon5 from "../../assets/v2/wechat-circle.svg";
import { lang_zh } from "@/utils/lang";
import link from "@/common/link";

const active = ref(-1);

const mouseenterHandler = (index) => {
  active.value = index;
};

const mouseleaveHandler = () => {
  active.value = -1;
};
</script>

<style scoped>
.bottom-content {
  padding-top: 64PX;
  display: flex;
  justify-content: space-between;
}
.logo-container {
  display: flex;
  align-items: center;
}
.log-img {
  width: 28PX;
}
.logo-name {
  color: #fff;
  font-family: Poppins;
  font-size: 20PX;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.188PX;
  margin-left: 10PX;
}
.opacity-text {
  color: #fff;
  font-family: Inter;
  font-size: 14PX;
  font-style: normal;
  font-weight: 400;
  line-height: 18PX;
  letter-spacing: -0.3PX;
  opacity: 0.5;
  line-height: 20PX; /* 142.857% */
}
.left-text {
  margin-top: 4PX;
}
.link-container {
  margin-top: 42PX;
  display: flex;
  gap: 24PX;
}
.link-container img {
  width: 32PX;
  height: 32PX;
}
.link-2 {
}
.info {
  margin-top: 16PX;
}
.footer-item-container {
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  justify-content: flex-end;
}
.footer-item-layout {
  margin-left: 40PX;
  display: flex;
  flex-direction: column;
}
.footer-item-title {
  color: var(--www-risecalendar-com-nero, #fff);
  font-family: Inter;
  font-size: 14PX;
  font-style: normal;
  font-weight: 500;
  line-height: 20PX; /* 142.857% */
  letter-spacing: -0.3PX;
  padding: 8PX;
}
.footer-item-text {
  padding: 8PX;
  width: 132PX;
}
.footer-item-text2 {
  padding: 8PX;
}
.item-child {
  display: flex;
  justify-content: center;
  white-space: nowrap;
}
.item-child img {
  margin-right: 12PX;
}
.item-text {
  flex: 1;
  white-space: normal;
  width: 230PX;
}
.wechat-popup {
  position: relative;
}
.wechat-popup:hover .qr-container {
  display: block;
}
.qr-container {
  display: none;
  padding-bottom: 8PX;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}
.qr-wrapper {
  padding: 12PX;
  border-radius: 12PX;
  background: #fff;
}
img.qr-img {
  width: 96PX;
  height: 96PX;
}
</style>
